// Item Model - item.js
var AmpModel = require('ampersand-model');

module.exports = AmpModel.extend({
    modelType: 'Item',
    idAttribute: 'token',
    props: {
        token: {type: 'string', required: true},
        type: {type: 'string', required: true},
        displayName: {type: 'string'},
        posX: {type: 'number'},
        posY: {type: 'number'},
        rotZ: {type: 'number'},
        bookable: {type: 'boolean'},
        validationActionText: {type: 'string', default: 'Valider ce vélo'},
        validationSuccessText: {type: 'string', default: 'Votre choix de vélo a été pris en compte'}
    },
    session: {
        state: {type: 'string', required: true, values: ['normal', 'selected'], default: 'normal'}
    },
    derived: {
        isSelected: {
            deps: ['state'],
            fn: function() {
                return this.state === 'selected';
            }
        },
        displayNumber: {
            deps: ['displayName'],
            fn: function() {
                return this.displayName.replace("N°","").replace("n°","");
            }
        }
    },
    initialize: function() {
        this.listenTo(this, 'change:state', function(model, value) {
            if (value !== 'normal' && model.collection) {
                model.collection.resetStates(model);
            }
        });
    }
});
