var config = (function (props) {
                        var self = {};
                        var _getByPropPath = function (o, s) {
                            // http://stackoverflow.com/a/6491621
                            s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
                            s = s.replace(/^\./, '');           // strip a leading dot
                            var a = s.split('.');
                            for (var i = 0, n = a.length; i < n; ++i) {
                                var k = a[i];
                                if (k in o) {
                                    o = o[k];
                                } else {
                                    return;
                                }
                            }
                            return o;
                        };

                        self.get = function (a) {
                            return _getByPropPath(props, a);
                        };

                        return self;
                    })({"Client":{"api":{"base_url":"https://api-biking.preprod-episod.stadline.com","client_token":"episod","authorization_header":"123456","post_message_enabled":true},"devmode":false,"oauth":{"enabled":false,"auth_url":"","client_id":"","client_secret":"","token_url":""}}});;

module.exports = config.get('Client');
