/*jshint browser: true*/

var Marionette = require('backbone.marionette');
var LayoutMapView = require('./layout-map-printable');

module.exports = Marionette.LayoutView.extend({
    template: function(data) {
        return require('../templates/page-session-print.nunj').render(data);
    },
    serializeData: function () {
        var layout = this.model.layout;
        var bookings = this.model.bookings;
        var attendees = [];

        layout.items.forEach(function(item) {

            if (!item.bookable)
            {
                return;
            }

            var booking = bookings.get(item.token, 'itemToken');
            if (booking) {
                attendees.push({
                    "item": item,
                    "booking": booking
                });
            } else {
                attendees.push({
                    "item": item
                });
            }
        });

        return {
            session: this.model,
            bookings: this.model.bookings.filter(function(booking) {
                return booking.itemToken;
            }),
            items: this.model.layout.items,
            attendees: attendees
        };
    },
    regions: {
        "session-map": ".region-session-map"
    },
    onBeforeShow: function() {

        this.showChildView('session-map', new LayoutMapView({
            model: this.model,
        }));
    },
    onAttach: function() {
        setTimeout(function() {
            window.print();
        }, 500);
    }
});