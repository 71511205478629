var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();
require( "./macro/marionette.nunj" );
var obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
env.getTemplate("./macro/marionette.nunj", false, undefined, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(t_1.hasOwnProperty("region")) {
var t_4 = t_1.region;
} else {
cb(new Error("cannot import 'region'")); return;
}
context.setVariable("region", t_4);
output += "\n\n<div class=\"print\">\n\n    <section>\n        <header class=\"hidden-landscape\">\n            <h1 class=\"title\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"name", env.opts.autoescape), env.opts.autoescape);
output += "</h1>\n            <ul class=\"list-unstyled\">\n                <li><i class=\"fa fa-calendar\"></i> ";
output += runtime.suppressValue(env.getFilter("toDateString").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"startedAt", env.opts.autoescape)), env.opts.autoescape);
output += " - ";
output += runtime.suppressValue(env.getFilter("toTimeString").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"startedAt", env.opts.autoescape)), env.opts.autoescape);
output += "</li>\n                <li><i class=\"fa fa-user\"></i> ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "bookings")),"length", env.opts.autoescape), env.opts.autoescape);
output += " réservation(s)</li>\n            </ul>\n        </header>\n\n        <div class=\"map\">\n            ";
output += runtime.suppressValue((lineno = 14, colno = 19, runtime.callWrap(t_4, "region", ["session-map"])), env.opts.autoescape);
output += "\n        </div>\n    </section>\n\n    <section>\n        <header class=\"hidden-portrait\">\n            <h1 class=\"title\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"name", env.opts.autoescape), env.opts.autoescape);
output += "</h1>\n            <ul class=\"list-unstyled\">\n                <li><i class=\"fa fa-calendar\"></i> ";
output += runtime.suppressValue(env.getFilter("toDateString").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"startedAt", env.opts.autoescape)), env.opts.autoescape);
output += " - ";
output += runtime.suppressValue(env.getFilter("toTimeString").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"startedAt", env.opts.autoescape)), env.opts.autoescape);
output += "</li>\n                <li><i class=\"fa fa-user\"></i> ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "bookings")),"length", env.opts.autoescape), env.opts.autoescape);
output += " réservation(s)</li>\n            </ul>\n        </header>\n\n        <div class=\"booking-list\">\n            <ul class=\"list-unstyled\">\n            ";
frame = frame.push();
var t_7 = runtime.contextOrFrameLookup(context, frame, "attendees");
if(t_7) {var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("attendee", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n                <li class=\"booking ";
if(runtime.memberLookup((t_8),"booking", env.opts.autoescape)) {
;
}
else {
output += "booking-free";
;
}
output += "\">\n                    <h4>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_8),"item", env.opts.autoescape)),"displayNumber", env.opts.autoescape), env.opts.autoescape);
output += "</h4>\n                        ";
if(runtime.memberLookup((t_8),"booking", env.opts.autoescape)) {
output += "\n                        ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_8),"booking", env.opts.autoescape)),"fullName", env.opts.autoescape), env.opts.autoescape);
output += "\n                        ";
;
}
else {
output += "\n                        &nbsp;\n                        ";
;
}
output += "\n                </li>\n            ";
;
}
}
frame = frame.pop();
output += "\n            </ul>\n        </div>\n    </section>\n</div>\n";
cb(null, output);
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
module.exports = require( "nunjucksify/runtime-shim" )(nunjucks, env, obj, require);
